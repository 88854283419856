<template>
  <el-table
    :data="filterRecordList()"
    border
    class="table"
    ref="multipleTable"
    :height="isForAdmin ? 280 : auto"
    highlight-current-row
    header-cell-class-name="table-header"
    @current-change="handleCurrentChange"
    :row-class-name="specifyTableRowClassName"
  >
    <el-table-column prop="studentId" label="Student No." width="140">
      <template v-if="isForAdmin" #header>
        <el-input
          v-model="filterQuery.studentId"
          size="small"
          :clearable="true"
          placeholder="Student No."
        />
      </template>
    </el-table-column>
    <el-table-column prop="studentName" label="Student Name">
      <template v-if="isForAdmin" #header>
        <el-input
          v-model="filterQuery.studentName"
          size="small"
          :clearable="true"
          placeholder="Student Name"
        />
      </template>
    </el-table-column>
    <el-table-column prop="foundation" label="Foundation" width="120">
      <template #header>
        Foundation({{ currentReport.foundationPercentage }}%)
      </template>
    </el-table-column>
    <el-table-column prop="development" label="Development" width="130">
      <template #header>
        Development({{ currentReport.developmentPercentage }}%)
      </template>
    </el-table-column>
    <el-table-column prop="enrichment" label="Enrichment" width="120">
      <template #header>
        Enrichment({{ currentReport.enrichmentPercentage }}%)
      </template>
    </el-table-column>
    <el-table-column prop="bonus" label="Bonus" width="70"> </el-table-column>
    <el-table-column prop="total" label="Total" width="70"> </el-table-column>
    <el-table-column
      prop="updatedTime"
      :formatter="$tableCellFormatter.formatDate"
      label="Updated Time"
      width="100"
    ></el-table-column>
  </el-table>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Total Histogram" name="totalHistogram" :lazy="false">
    </el-tab-pane>
    <el-tab-pane
      label="Foundation Histogram"
      name="foundationHistogram"
      :lazy="false"
    >
    </el-tab-pane>
    <el-tab-pane
      label="Development Histogram"
      name="developmentHistogram"
      :lazy="false"
    >
    </el-tab-pane>
    <el-tab-pane
      label="Enrichment Histogram"
      name="enrichmentHistogram"
      :lazy="false"
    >
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { getData } from "../../service/api";
import { Chart, registerables } from "chart.js";

export default {
  props: {
    isForAdmin: Boolean,
    studentId: String,
    reportId: String,
  },
  data() {
    return {
      controllerUrl: "/testReport",
      query: {
        studentId: null,
        reportId: null,
        pageIndex: 1,
        pageSize: 0x7fffffff,
        orderBy: "StudentName",
        orderDirection: "ASC",
      },
      filterQuery: {
        studentId: null,
        studentName: null,
      },
      styleConfig: {
        bar: {
          total: {
            font: "bold 12px Arial",
            bgColor: "rgba(187, 219, 253, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
          },
          foundation: {
            font: "bold 12px Arial",
            bgColor: "rgba(187, 219, 253, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
          },
          development: {
            font: "bold 12px Arial",
            bgColor: "rgba(187, 219, 253, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
          },
          enrichment: {
            font: "bold 12px Arial",
            bgColor: "rgba(187, 219, 253, 0.5)",
            foreColor: "rgba(255, 255, 255, 1)",
            textAlign: "start",
          },
        },
        line: {
          average: {
            font: "bold 12px Arial",
            bgColor: "rgba(0, 0, 0, 0.2)",
            foreColor: "rgba(0, 0, 0, 0.2)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: false,
          },
          median: {
            font: "bold 12px Arial",
            bgColor: "rgba(0, 125, 255, 0.2)",
            foreColor: "rgba(0, 125, 255, 0.5)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: true,
            dashPattern: [9, 10],
          },
          grade: {
            font: "bold 12px Arial",
            bgColor: "rgba(255, 0, 0, 0.2)",
            foreColor: "rgba(255, 0, 0, 0.5)",
            lineWidth: 3,
            textAlign: "start",
            drawText: false,
            isDashLine: false,
          },
          ranking: {
            font: "bold 12px Arial",
            foreColor: "#000000",
            foreColor1: "#fd0410",
            foreColor2: "#ffa500",
            foreColor3: "#233c64",
            textAlign: "start",
          },
        },
      },
      testReportSummary: {
        recordsDistribution: {},
        summarizedData: {},
        topStudents: {},
      },
      startAndStepsSettingList: [{}],
      activeName: "totalHistogram",
      currentReport: { recordList: [] },
      currentStudent: {},
      chartList: [],
      chartContext: {
        options: {
          indexAxis: "x",
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: false,
              position: "top",
              text: "TT Histogram",
            },
            tooltip: {
              callbacks: {
                title: function() {
                  return "";
                },
                label: function(tooltipItem) {
                  let dataset = tooltipItem.dataset;
                  let dataPoint = dataset.data[tooltipItem.dataIndex];
                  let label = tooltipItem.label;
                  let recordType = dataset.recordType;
                  let _this = dataset.othis;
                  let startAndStepsSetting = _this.startAndStepsSettingList.find(
                    (e) => e.column === recordType
                  );
                  if (startAndStepsSetting == null) return;
                  let steps = startAndStepsSetting.steps;
                  let end = parseInt(label);
                  let start = end - steps;
                  if (start < startAndStepsSetting.start) {
                    start = steps;
                  }
                  return `${start} - ${end}: ${dataPoint}`;
                },
              },
            },
          },
          layout: {
            padding: {
              top: 10,
              right: 160,
              bottom: 3,
              left: 3,
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
            y: {
              barPercentage: 1.0,
              beginAtZero: false,
              // ticks: {
              //   callback: function(value) {
              //     let val = this.getLabelForValue(value);
              //     if (val % 10 === 0) {
              //       return val;
              //     }
              //     return "";
              //   },
              // },
            },
          },
          animation: {
            onComplete: function() {
              let chartInstance = this;
              let ctx = chartInstance.ctx;
              ctx.textAlign = "center";

              chartInstance.data.datasets.forEach(function(dataset, i) {
                let meta = chartInstance.getDatasetMeta(i);
                meta.data.forEach(function(bar, index) {
                  let data = dataset.data[index];
                  let _this = dataset.othis;
                  let recordType = dataset.recordType;
                  let labelAndValueObj = meta.controller.getLabelAndValue(
                    index
                  );
                  if (!_this.currentStudent) return;
                  let record = _this.currentStudent[recordType];
                  let startAndStepsSetting = _this.startAndStepsSettingList.find(
                    (e) => e.column === recordType
                  );
                  if (startAndStepsSetting == null) return;
                  let steps = startAndStepsSetting.steps;
                  let end = parseInt(labelAndValueObj.label);
                  let start = end - steps;
                  if (start < startAndStepsSetting.start) {
                    start = steps;
                  }
                  let barStyle = _this.styleConfig.bar[recordType];
                  if (data > 0 && record > start && record <= end) {
                    ctx.font = barStyle.font;
                    ctx.fillStyle = barStyle.foreColor;
                    // if (_this.query.studentId) {
                    //   ctx.fillText("You are here", bar.x / 2, bar.y + 5);
                    // } else {
                    //   ctx.fillText(
                    //     `${_this.currentStudent.studentName} is here`,
                    //     bar.x / 2,
                    //     bar.y + 5
                    //   );
                    // }
                  }
                });
              });
            },
          },
        },
        chartData: {
          // average: {
          //   labels: [],
          //   datasets: [
          //     {
          //       label: "Average",
          //       data: [],
          //       backgroundColor: "#4F81BD",
          //     },
          //   ],
          // },
          foundation: {
            labels: [],
            datasets: [
              {
                label: "Foundation Frequency",
                data: [],
                backgroundColor: "#4F81BD",
                borderWidth: 0,
              },
            ],
          },
          development: {
            labels: [],
            datasets: [
              {
                label: "Development Frequency",
                data: [],
                backgroundColor: "#4F81BD",
                borderWidth: 0,
              },
            ],
          },
          enrichment: {
            labels: [],
            datasets: [
              {
                label: "Enrichment Frequency",
                data: [],
                backgroundColor: "#4F81BD",
                borderWidth: 0,
              },
            ],
          },
          total: {
            labels: [],
            datasets: [
              {
                label: "Total Frequency",
                data: [],
                backgroundColor: "#4F81BD",
              },
            ],
          },
        },
      },
    };
  },
  watch: {
    reportId(newValue) {
      console.log("watch.reportId", newValue);
      if (newValue) {
        if (newValue !== this.query.reportId) {
          this.initAndLoadData(newValue, this.studentId);
        }
      }
    },
  },
  created() {
    Chart.register(...registerables);
    this.initAndLoadData(this.reportId, this.studentId);
  },
  mounted() {},
  methods: {
    initAndLoadData(reportId, studentId) {
      this.query.reportId = reportId;
      if (studentId) {
        this.query.studentId = studentId;
      }
      this.loadData();
    },
    async loadData() {
      let url = "";
      let currentReport = null;

      if (this.isForAdmin) {
        url = `${this.controllerUrl}/getTestReportAndRecords/${this.query.reportId}`;
      } else {
        url = `${this.controllerUrl}/getTestReportAndRecordsForCurrentStudent/${this.query.reportId}`;
      }
      let res = await getData(url);
      if (res.result && res.code === "200") {
        console.log("TestReportChart.loadData", res.result);
        let recordList = res.result.recordList.filter((e) => e.total > 0);
        res.result.recordList = recordList;
        currentReport = res.result;
      }

      this.startAndStepsSettingList = [];
      for (let recordType in this.styleConfig.bar) {
        let startAndStepsSetting = {};
        let start = 0;
        let end = 0;
        let steps = 0;
        if (recordType !== "total") {
          end = currentReport[`${recordType}Percentage`];
          steps = Math.ceil(parseInt(end) / 10);
        } else {
          end = 110;
          steps = Math.ceil(parseInt(end) / 11);
        }
        if (recordType !== "total") {
          start = 0;
        } else {
          start = 40;
        }

        startAndStepsSetting["column"] = recordType;
        startAndStepsSetting["start"] = start;
        startAndStepsSetting["end"] = end;
        startAndStepsSetting["steps"] = steps;

        this.startAndStepsSettingList.push(startAndStepsSetting);
      }

      let recordsDistributionDic = null;
      let summarizedDataDic = null;
      let topStudentsDic = null;
      if (this.isForAdmin) {
        url = `${this.controllerUrl}/getTestReportSummary/${this.query.reportId}`;
      } else {
        url = `${this.controllerUrl}/getTestReportSummaryForStudent/${this.query.reportId}`;
      }
      console.log("loadData", url, this.startAndStepsSettingList);

      res = await getData(url, {
        startAndStepsSettings: JSON.stringify(this.startAndStepsSettingList),
      });
      if (res.result && res.code === "200") {
        let retData = res.result;
        recordsDistributionDic = retData.recordsDistribution;
        summarizedDataDic = retData.summarizedData;
        topStudentsDic = retData.topStudents;

        this.testReportSummary = retData;
      }

      this.currentReport = currentReport;

      this.chartList = [];
      for (let i = 0; i < this.startAndStepsSettingList.length; i++) {
        let startAndStepsSetting = this.startAndStepsSettingList[i];
        let parentNode = document.getElementById(
          `pane-${startAndStepsSetting.column}Histogram`
        );
        parentNode.innerHTML = "";
        this.renderBarChart(
          parentNode,
          recordsDistributionDic,
          startAndStepsSetting,
          summarizedDataDic,
          topStudentsDic
        );
      }

      if (!this.isForAdmin) {
        this.handleCurrentChange(this.currentReport.recordList[0]);
      }
    },
    renderBarChart(
      parentNode,
      recordsDistributionDic,
      startAndStepsSetting,
      summarizedDataDic,
      topStudentsDic
    ) {
      const totalLegendLength = 12;
      let recordType = startAndStepsSetting.column;
      let start = startAndStepsSetting.start;
      let end = startAndStepsSetting.end;
      let steps = startAndStepsSetting.steps;
      let dataList = [];
      let labelList = [];
      let recordList = [];
      let summaryData = [];
      let topStudents = [];
      let camelName =
        recordType.substr(0, 1).toUpperCase() + recordType.substring(1);

      let segmentCount = Math.ceil((end - start) / steps);
      for (let t = 0; t <= segmentCount; t++) {
        let label = t * steps + start;
        if (label > 0) labelList.push(label);
      }

      for (let type in recordsDistributionDic) {
        if (recordType !== type) continue;
        let maxVal = 0;
        this.chartContext.chartData[recordType].datasets = [];
        recordList = recordsDistributionDic[recordType];
        summaryData = summarizedDataDic[recordType];
        topStudents = topStudentsDic[recordType];
        for (let i = 0; i < labelList.length; i++) {
          let label = labelList[i];
          let record = recordList.find((e) => e.name === `${label}`);
          if (record) {
            console.log("record.value", record.value);
            dataList.push(record.value);
            if (maxVal < record.value) maxVal = record.value;
          } else {
            dataList.push(0);
          }
        }
        // labelList = labelList.reverse();
        // dataList = dataList.reverse();

        this.chartContext.chartData[recordType].labels = labelList;
        let dataset = {
          label: `${camelName} Frequency`.padEnd(totalLegendLength + 9, " "),
          data: dataList,
          backgroundColor: this.styleConfig.bar[recordType].bgColor,
          borderWidth: {
            top: 2,
            right: 1,
            bottom: 0,
            left: 1,
          },
          borderColor: "rgba(64, 158, 255, 0.3)",
          borderSkipped: "false",
          othis: this,
          recordType: recordType,
        };
        this.chartContext.chartData[recordType].datasets.push(dataset);

        if (recordType === "total") {
          let datasetForMyScoreLine = {
            type: "line",
            label: "My Score",
            data: [],
            backgroundColor: this.styleConfig.line.grade.bgColor,
            borderWidth: 0,
          };
          this.chartContext.chartData[recordType].datasets.push(
            datasetForMyScoreLine
          );

          let datasetForMyMedianLine = {
            type: "line",
            label: "Median",
            data: [],
            backgroundColor: this.styleConfig.line.median.bgColor,
            borderWidth: 0,
          };
          this.chartContext.chartData[recordType].datasets.push(
            datasetForMyMedianLine
          );
        }

        let ctx = document.createElement("canvas");
        let div = document.createElement("div");
        div.appendChild(ctx);
        parentNode.appendChild(div);

        let opt = this.clone(this.chartContext.options);
        opt.plugins.title.text = `${camelName} Histogram`;
        opt.scales.y.max = maxVal + maxVal * 0.5;
        opt.plugins.legend.onClick = function(evt, item) {
          console.log("opt.plugins.legend.onClick", arguments);
          Chart.defaults.plugins.legend.onClick.call(this, evt, item, this);
          if (evt.chart && evt.chart.chartState) {
            if (item.text === "My Score") {
              evt.chart.chartState.additionalData.hiddenMyScore = item.hidden;
            } else if (item.text === "Median") {
              evt.chart.chartState.additionalData.hiddenMedian = item.hidden;
            }
          }
        };
        let chart = new Chart(ctx, {
          plugins: [
            {
              afterDraw: function(chart) {
                let styleObj = null;
                console.log("afterDraw", chart);
                if (!chart.chartState) return;
                if (recordType !== "total") return;
                let chartState = chart.chartState;
                let dataList = chartState.summaryData;
                // let averageData = dataList.find(
                //   (item) => item.name.toLowerCase() === "average"
                // );
                // if (averageData) {
                //   styleObj = chartState.othis.styleConfig.averageLine;

                //   chartState.othis.drawHorizontalLine(
                //     chart,
                //     averageData,
                //     styleObj,
                //     count,
                //   );
                // }
                if (!chartState.additionalData.hiddenMedian) {
                  let medianData = dataList.find(
                    (item) => item.name.toLowerCase() === "median"
                  );
                  if (medianData) {
                    styleObj = chartState.othis.styleConfig.line.median;
                    chartState.othis.drawHorizontalLine(
                      chart,
                      medianData,
                      styleObj
                    );
                  }
                }
                if (
                  chartState.othis.currentStudent &&
                  !chartState.additionalData.hiddenMyScore
                ) {
                  let scoreTitle = "";
                  if (chartState.othis.query.studentId) {
                    scoreTitle = "My Score";
                  } else {
                    scoreTitle = `${chartState.othis.currentStudent.studentName}'s score`;
                  }
                  styleObj = chartState.othis.styleConfig.line.grade;
                  chartState.othis.drawHorizontalLine(
                    chart,
                    {
                      name: scoreTitle,
                      value: chartState.othis.currentStudent[recordType],
                    },
                    styleObj
                  );
                }

                styleObj = chartState.othis.styleConfig.line.ranking;
                chartState.othis.drawTopStudentsText(
                  chart,
                  chartState.topStudents,
                  styleObj
                );
              },
            },
            {
              beforeInit(chart) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                  originalFit.bind(chart.legend)();
                  this.height += 30;
                };
              },
            },
          ],
          type: "bar",
          data: this.chartContext.chartData[recordType],
          options: opt,
        });
        chart["chartState"] = {
          othis: this,
          recordType: recordType,
          labelList: labelList,
          dataList: dataList,
          summaryData: summaryData,
          topStudents: topStudents,
          additionalData: {
            hiddenMyScore: false,
            hiddenMedian: false,
          },
        };

        this.chartList.push(chart);
      }
    },
    drawHorizontalLine(chart, gradeData, styleObj) {
      if (!chart.chartState) return;
      let chartState = chart.chartState;
      let index = chartState.othis.getIndexValue(
        chartState.labelList,
        gradeData.value
      );
      if (index < 0) return;
      let xaxis = chart.scales["x"];
      let yaxis = chart.scales["y"];
      let xPos = xaxis.getPixelForValue(index, undefined);

      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      if (styleObj.isDashLine) {
        ctx.setLineDash(styleObj.dashPattern);
      }
      ctx.moveTo(xPos, yaxis.bottom);
      ctx.strokeStyle = styleObj.bgColor;
      ctx.lineWidth = styleObj.lineWidth;
      ctx.lineTo(xPos, yaxis.top);
      ctx.stroke();
      ctx.restore();
      if (styleObj.drawText) {
        ctx.font = styleObj.font;
        ctx.fillStyle = styleObj.foreColor;
        let txt = `${gradeData.name}: ${gradeData.value}`;
        let measure = ctx.measureText(txt);
        ctx.fillText(txt, xPos - measure.width / 2, yaxis.top - 10);
      }
    },
    drawTopStudentsText(chart, topStudents, styleObj) {
      if (!chart.chartState) return;
      let chartState = chart.chartState;
      let xaxis = chart.scales["x"];
      let yaxis = chart.scales["y"];
      let ctx = chart.ctx;
      let recordType = chartState.recordType;
      let rightPos = xaxis.right + 12;
      let paddingTop = 12;
      let lineHeight = 18;
      let i = 0;

      ctx.font = styleObj.font;
      ctx.fillStyle = styleObj.foreColor;
      ctx.textAlign = styleObj.textAlign;

      ctx.fillText("Top Achievers", rightPos, yaxis.top + paddingTop);

      for (; i < topStudents.length; i++) {
        ctx.fillStyle = styleObj[`foreColor${i + 1}`];
        ctx.fillText(
          `${i + 1}. ${topStudents[i].studentName}: ${
            topStudents[i][recordType]
          }`,
          rightPos,
          yaxis.top + paddingTop + lineHeight * (i + 1)
        );
      }

      ctx.fillStyle = styleObj.foreColor;
      if (chartState.othis.currentStudent) {
        let dataList = chartState.summaryData;
        let totalRecordsData = dataList.find(
          (item) => item.name.toLowerCase() === "totalrecords"
        );
        if (totalRecordsData) {
          if (chartState.othis.isForAdmin) {
            let list = JSON.parse(
              JSON.stringify(this.currentReport.recordList)
            );
            list.sort((x, y) => {
              return y[recordType] - x[recordType];
            });
            let index = list.findIndex(
              (item) =>
                item.studentId === chartState.othis.currentStudent.studentId
            );
            if (index >= 0) {
              let rank = index + 1;
              ctx.fillText(
                `My Rank: ${rank}/${totalRecordsData.value}`,
                rightPos,
                yaxis.top + paddingTop + lineHeight * (i + 1)
              );
            }
          } else {
            let myRankData = dataList.find(
              (item) => item.name.toLowerCase() === "myrank"
            );
            ctx.fillText(
              `My Rank: ${myRankData.value}/${totalRecordsData.value}`,
              rightPos,
              yaxis.top + paddingTop + lineHeight * (i + 1)
            );
          }
        }
      }
    },
    getIndexValue(labelList, val) {
      if (val > labelList[labelList.length - 1] || val < 0) return -1;
      for (let i = 0; i < labelList.length; i++) {
        if (labelList[i] === val) {
          return i;
        } else if (val > labelList[i] && val < labelList[i + 1]) {
          let valRight = labelList[i + 1];
          let valLeft = labelList[i];

          return i + ((val - valLeft) / (valRight - valLeft)) * 1.0;
        }
      }
    },
    handleCurrentChange(row) {
      if (this.currentStudent === row) return;
      this.currentStudent = row;
      this.chartList.forEach((e) => e.render());
    },
    clone(target) {
      if (typeof target === "object") {
        let cloneTarget = Array.isArray(target) ? [] : {};
        for (const key in target) {
          cloneTarget[key] = this.clone(target[key]);
        }
        return cloneTarget;
      } else {
        return target;
      }
    },
    filterRecordList() {
      let recordList = this.currentReport.recordList;
      recordList = recordList.filter((e) => {
        return (
          ((this.filterQuery.studentId || "").length === 0 ||
            e.studentId.includes(this.filterQuery.studentId)) &&
          ((this.filterQuery.studentName || "").length === 0 ||
            e.studentName
              .toLowerCase()
              .includes(this.filterQuery.studentName.toLowerCase()))
        );
      });

      return recordList;
    },
    specifyTableRowClassName({ row }) {
      console.log("specifyTableRowClassName", row);
      let topStudents = this.testReportSummary.topStudents;
      let list = topStudents.total;
      let index = -1;
      if ((index = list.findIndex((e) => e.studentId === row.studentId)) >= 0) {
        return `top${index + 1}`;
      } else {
        return "";
      }
    },
  },
};
</script>
<style>
.el-table .top1 {
  background: inherit;
}
.el-table .top2 {
  background: inherit;
}
.el-table .top3 {
  background: inherit;
}
</style>
